.Header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: grey;
  font-size: 3rem;
  position: relative;
  z-index: 10;
  height: 100px;
}

.Content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100vh;
  margin-top: -100px;
  padding-top: 100px;
  position: relative;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: overflow;
}

.LoginForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

svg.logo {
  max-width: 80%;
  max-height: 80%;
  margin-bottom: 16px;
}

svg.logo path,
svg.logo polygon {
  fill-opacity: 1;
  fill: #333 !important;
}

.CheckinContainer,
.CookingTimesContainer,
.CookingSkusContainer {
  overflow: auto;
  height: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
}

.CheckinContainer::-webkit-scrollbar,
.CookingTimesContainer::-webkit-scrollbar,
.CookingSkusContainer::-webkit-scrollbar,
.CheckinContainer {
  width: 20vw;
  height: 100vh;
  background-color: rgb(246, 247, 248);
  border-right: 2px solid rgb(58, 61, 58);
}

.CookingTimesContainer {
  width: 20vw;
  height: 100vh;
  background-color: rgb(246, 247, 248);
  border-right: 2px solid rgb(58, 61, 58);
}

.CookingSkusContainer {
  width: 60vw;
  height: 100vh;
  background-color: rgb(246, 247, 248);
  text-align: left;
  padding-left: 10px;
}

.CommonRowStyle {
  padding: 10px;
}

.ant-table-thead > tr > th {
  font-weight: bold;
  border-bottom: 1px solid #333;
}

.ant-table-thead > tr > th.OffHour,
td.OffHour,
.OffHour {
  background-color: rgba(255, 235, 180, 0.6);
}

.Legends h5 {
  margin-bottom: 0;
  text-align: left;
}

.Legend span {
  font-size: 11px;
  float: left;
}

.Legend .OffHour {
  margin-left: 12px;
  width: 16px;
  height: 16px;
  display: block;
  border: 1px solid gray;
}

.FormEntry > div:first-child {
  float: left;
}

.FormEntry > div {
  float: right;
  width: 200px;
  text-align: left;
}

.FormEntry input {
  float: left;
  max-width: 100%;
}

.FormEntry .ant-input-number {
  max-width: 100px;
}

.ant-form-item {
  margin-bottom: 12px;
}

.ant-modal-body {
  max-height: calc(90vh - 200px);
  overflow: scroll;
}

.ant-table-tbody .Committed {
  font-weight: bold;
  font-size: 18px;
  background-color: rgb(240, 240, 240);
}

.ant-table-fixed tr td,
.ant-table-thead tr th {
  text-align: center;
}

.ant-table-fixed-left .ant-table-fixed tr td:first-child {
  text-align: left;
  font-size: 13px;
  font-weight: bold;
}

.Actions button {
  float: right;
}

.CookingSkus,
#root,
.App,
.App > div,
html,
body {
  width: 100%;
  height: 100%;
  overscroll-behavior: none;
}

.ant-table-body {
  background-color: white;
}

.CookingSkusChanged {
  background-color: red;
}

.ant-table-thead > tr > th {
  min-width: 100px !important;
  word-break: break-all;
}

.ant-table-tbody > tr > td {
  min-width: 100px !important;
  word-break: break-all;
}

.ant-table-thead > tr > th.Meals,
td.Meals,
.Meals {
  width: 200px;
}

.ant-table-thead > tr > th.Committed,
td.Committed,
.Committed {
  width: 120px;
}

.ant-table-tbody .ant-table-row,
.ant-table-tbody .ant-table-row td,
.ant-table-thead th,
.ant-table-fixed-columns-in-body {
  height: 60px !important;
  padding: 10px;
}

.DismissMe {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(255,0,0,0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.DismissMe h1 {
  font-weight: bold;
  color: #000;
}

.DismissMe .ant-btn {
  width: 100%;
  height: 60px;
  max-width: 340px;
  font-size: 18px;
  font-weight: bold;
  background-color: #333;
  border: 1px solid #000000;
}
