@import '~antd/dist/antd.css';

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
